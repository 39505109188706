import React from "react"
import Layout from "../../../components/sermonLayoutPhoenix"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout
    sermonSrc="https://www.youtube.com/embed/c4oBOibAXWM"
    notesSrc="http://bible.com/events/7154034"
    notesText="Sermon Notes"
    bibleGroupSrc="https://www.docdroid.net/d49zPTZ/bible-group-homework-3-29.pdf"
    bibleGroupText="Bible Group Homework"
  >
    <SEO title="When People Pray - Our Perspective Changes" />
  </Layout>
)

export default SermonPost
